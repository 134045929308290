export const initFormControls = () => {


    const form = document.getElementById('mc-embedded-subscribe-form');
    const requiredInputs = form.querySelectorAll('.RS-required');
    const submitInput = form.querySelector('[type="submit"]');
    const submitInputText = form.querySelector('.RS-button__content');
    const submitButtonLoader = form.querySelector('.RS-button__loader');


    window.addEventListener("pageshow", () => {
        submitInput.disabled = false;
        submitInput.classList.remove('RS-submit--is-hidden');
        submitInputText.classList.remove('RS-submit-text--is-hidden');
        // submitButtonArrow.classList.remove('RS-button__arrow--is-hidden');
        submitButtonLoader.classList.remove('RS-button__loader--is-running');
    });

  
    form.addEventListener('submit', function (e) {
      e.preventDefault();
      submitInput.blur();
      let hasError = false;
      // console.log(hasError);
  
      requiredInputs.forEach(input => {
        if (input.type === 'text' || input.type === 'email') {
          if (!input.value.trim()) {
            showError(input, 'To pole jest wymagane');
            hasError = true;
            // console.log('jakiś text lub email pusty, o ten:' + input.getAttribute('name'));
          } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
            showError(input, 'Niepoprawny format email');
            hasError = true;
            // console.log('format email niepoprawny');
          } else {
            hideError(input);
            // console.log('brak errorów w text lub email');
          }
        } else if (input.type === 'radio' && input.classList.contains('RS-input--radio-checkbox')) {
            const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
            if (checkedRadioCheckboxes.length !== 1) {
                // console.log('zgoda niezaznaczona');
                showError(input, 'Zaznaczenie zgody jest wymagane');
                hasErrors = true;
            }else{
                // console.log('zgoda zaznaczona');
                hideError(input);
                hasErrors = false;
            }
        }else if (input.type === 'radio' && !input.classList.contains('RS-input--radio-checkbox')) {
            const radioGroup = input.closest('.RS-radio-group');
            const radioButtons = radioGroup.querySelectorAll('input[type="radio"]');
            const checkedRadioButtons = radioGroup.querySelectorAll('input[type="radio"]:checked');
            if (radioButtons.length > 0 && checkedRadioButtons.length === 0) {
                showError(input, 'To pole jest wymagane');
                hasError = true;
                // console.log('jakiś radios normalny nie zaznaczony');
            } else {
                hideError(input);
                hasErrors = false;
                // console.log('wszystkie radiosy normalne zaznaczone');
            }
        }
      });
  
      if (!hasError) {
        const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
        // console.log(checkedRadioCheckboxes.length);
        if(checkedRadioCheckboxes.length === 1){
            // console.log('should submit');
            form.submit();
            submitInput.disabled = true;
            submitInput.classList.add('RS-submit--is-hidden');
            submitInputText.classList.add('RS-submit-text--is-hidden');
            submitButtonLoader.classList.add('RS-button__loader--is-running');
        }
      }else{
        const firstVisibleError = form.querySelector('.RS-error.RS-error--is-visible');
        if (firstVisibleError) {
            const closestFieldGroup = firstVisibleError.closest('.RS-field-group');
            // const closestInput = closestFieldGroup.querySelector('input');
            if (closestFieldGroup) {
                closestFieldGroup.scrollIntoView({block: 'center', behavior: 'smooth'});
                // closestFieldGroup.scrollIntoView({block: 'center'});
                // if (closestInput) {
                //     closestInput.focus();
                // }
            }
        }
      }
    });

    

    const textEmailInputs = form.querySelectorAll("input[type='text'], input[type='email']");
    textEmailInputs.forEach(function (input) {
        input.addEventListener("blur", function () {
            if (input.value.trim() === "") {
                showError(input, 'To pole jest wymagane');
            } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
                showError(input, 'Niepoprawny format email');
            } else {
                hideError(input);
            }
        });
        input.addEventListener("keyup", function () {
            if (input.value.trim() === "") {
                showError(input, 'To pole jest wymagane');
            } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
                // showError(input, 'Niepoprawny format email');
            } else {
                hideError(input);
            }
        });
    });


    const radioCheckboxInputLabels = form.querySelectorAll("input[type='radio'].RS-input--radio-checkbox + label");
    radioCheckboxInputLabels.forEach(function (label) {
        const input = label.previousElementSibling;
        input.addEventListener("change", function () {
        const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
            if(checkedRadioCheckboxes.length === 1){
                hideError(label);
            } 
        });
    });

    const radioInputLabels = form.querySelectorAll("input[type='radio']+ label");
    radioInputLabels.forEach(function (label) {
        const input = label.previousElementSibling;
        input.addEventListener("change", function () {
            const radioGroup = input.closest('.RS-radio-group');
            const radioButtons = radioGroup.querySelectorAll('input[type="radio"]');
            const checkedRadioButtons = radioGroup.querySelectorAll('input[type="radio"]:checked');
            if (radioButtons.length > 0 && checkedRadioButtons.length !== 0) {
                hideError(label);
            } 
        });
    });

  
    function showError(input, message) {
        const errorSpan = input.closest('.RS-field-group').querySelector('.RS-error');
        if (errorSpan) {
            errorSpan.classList.add('RS-error--is-visible');
            errorSpan.textContent = message;
        }
    }
    
    function hideError(input) {
        const errorSpan = input.closest('.RS-field-group').querySelector('.RS-error');
        if (errorSpan) {
            errorSpan.classList.remove('RS-error--is-visible');
        }
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

};

export const initFaq = () => {
  const singleFaq = document.querySelectorAll('.RS-faq__single');

  singleFaq.forEach(faq => {
    const question = faq.querySelector('.RS-faq__single-question');
    const answer = faq.querySelector('.RS-faq__single-answer');
    const icon = faq.querySelector('.RS-faq-icon');
    
    question.addEventListener('click', () => {
      answer.classList.toggle('RS-faq__single-answer--expanded');
      icon.classList.toggle('RS-faq-icon--expanded');
      answer.scrollIntoView({ 
        behavior: "smooth", 
        block: "center", 
        top: answer.getBoundingClientRect().top + window.scrollY + 300 
      });
    });
  });

};
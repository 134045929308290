export const initVideo = () => {
  // const btnScrollToVideoAndPlay = document.getElementById('RS-careers-btn-scroll-to-video-and-play');
  const videoOverlay = document.getElementById('RS-video__overlay');
  const videoWrapper = document.getElementById('RS-video__wrapper');
  const videoWrapperInner = document.getElementById('RS-video__wrapper-inner');
  // const sectionVideo = document.getElementById('RS-section-careers-video');
  const video = document.getElementById('RS-video');
  // const scrollTimeout = 900;

  // btnScrollToVideoAndPlay.addEventListener('click', () => {
  //   videoOverlay.classList.add('RS-video-overlay--hidden');
  //   setTimeout(() => {
  //     videoCareers.play();
  //   }, scrollTimeout);
  // });

  videoOverlay.addEventListener('click', () => {
    videoOverlay.classList.add('RS-video__overlay--hidden');
    videoWrapper.classList.add('RS-video-wrapper--launched');
    videoWrapperInner.classList.remove('RS-video__wrapper-inner--hidden');
    video.play();
    
    video.scrollIntoView({ block: 'center' });
  });
};